import { ArrowDown, ArrowUp, InfoCircle, Pencil, RemoveIcon } from "components/LEGACY/styled-components/Icons"
import styled, { css } from "styled-components"

interface ButtonProps {
  fontSize?: string
  bold?: boolean
  width?: string
  margin?: string
  whiteDanger?: boolean
  success?: boolean
  danger?: boolean
  warning?: boolean
  yellow?: boolean
  darkgrey?: boolean
  secondary?: boolean
  ghost?: boolean
  ghostGreen?: boolean
}

export const Button = styled.button<ButtonProps>`
  border: 1px solid transparent;
  background: ${props => props.theme.gradient};
  background-color: ${props => props.theme.secondary};
  color: ${props => props.color || "#fff"};
  display: ${props => (props.hidden ? "none" : "flex")};
  font-weight: 400;
  padding: 0.5625rem 1rem;
  font-size: ${props => props.fontSize || "0.75rem"};
  font-weight: ${props => props.bold && "bold"};
  line-height: 1.125;
  border-radius: 5px;
  transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
  width: ${props => props.width};
  vertical-align: middle;
  align-items: center;
  margin: ${props => props.margin};
  outline: none;
  touch-action: manipulation;
  white-space: nowrap;
  text-transform: uppercase;

  &:focus {
    outline: none;
    box-shadow:
      rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }
  &:hover {
    outline: none;
    box-shadow:
      rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }

  ${props =>
    props.whiteDanger &&
    css`
      border-color: ${props => props.theme.danger};
      color: ${props => props.theme.danger};
      background: white;
    `}

  ${props =>
    props.success &&
    css`
      border-color: transparent;
      background: ${props => props.theme.gradient};
    `}

  ${props =>
    props.danger &&
    css`
      border-color: ${props => props.theme.danger};
      background: ${props => props.theme.danger};
    `}
  ${props =>
    props.warning &&
    css`
      border-color: ${props => props.theme.warning};
      background: ${props => props.theme.warning};
    `}
    ${props =>
    props.yellow &&
    css`
      color: ${props => props.theme.darkgrey};
      border-color: ${props => props.theme.yellow};
      background: ${props => props.theme.yellow};
    `}

      ${props =>
    props.darkgrey &&
    css`
      border-color: ${props => props.theme.darkgrey};
      background: ${props => props.theme.darkgrey};
    `}

      ${props =>
    props.secondary &&
    css`
      border-color: transparent;
      background: ${props => props.theme.gradient};
    `}

        ${props =>
    props.ghost &&
    css`
      border-color: ${props => props.theme.dark};
      background: unset;
      background-color: transparent;
      color: ${props => props.theme.dark};
    `}

        ${props =>
    props.ghostGreen &&
    css`
      border-color: ${props => props.theme.secondary};
      background: unset;
      background-color: transparent;
      color: ${props => props.theme.secondary};
    `}

    

      ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 60%;
      &:hover {
        box-shadow: none;
      }
    `}
`

export const ExternalLinkButton = styled(Button)``

interface EditButtonProps {
  margin?: string
}

export const EditButton = styled(Pencil)<EditButtonProps>`
  color: ${props => props.theme.darkgrey};
  cursor: pointer;
  margin: ${props => props.margin || "unset"};
  margin-right: 0.5rem;
  &:hover {
    color: var(--secondary);
  }
`

export const InfoButton = styled(InfoCircle)`
  color: ${props => props.theme.darkgrey};
  cursor: pointer;
  margin-right: 0.5rem;
  &:hover {
    color: var(--secondary);
  }
`

interface InfoButtonProps {
  margin?: string
}

export const RemoveButton = styled(RemoveIcon)<InfoButtonProps>`
  color: ${props => props.theme.warning};
  cursor: pointer;
  margin: ${props => props.margin};
  &:hover {
    color: ${props => props.theme.danger};
  }
`

interface UpButtonProps {
  margin?: string
  disabled?: boolean
}

export const UpButton = styled(ArrowUp)<UpButtonProps>`
  color: ${props => props.theme.darkgrey};
  cursor: pointer;
  margin: ${props => props.margin};
  &:hover {
    color: ${props => props.theme.secondary};
  }
  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 60%;
      &:hover {
        color: ${props => props.theme.darkgrey};
        box-shadow: none;
      }
    `}
`

interface DownButtonProps {
  margin?: string
  disabled?: boolean
}

export const DownButton = styled(ArrowDown)<DownButtonProps>`
  color: ${props => props.theme.darkgrey};
  cursor: pointer;
  margin: ${props => props.margin};
  &:hover {
    color: ${props => props.theme.secondary};
  }
  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 60%;
      &:hover {
        color: ${props => props.theme.darkgrey};
        box-shadow: none;
      }
    `}
`

interface TextButtonProps {
  margin?: string
  primary?: boolean
  secondary?: boolean
  warning?: boolean
}

export const TextButton = styled.p<TextButtonProps>`
  color: ${props =>
    (props.secondary && props.theme.secondary) ||
    (props.primary && props.theme.primary) ||
    (props.warning && props.theme.warning)};
  cursor: pointer;
  margin: ${props => props.margin};

  &:hover {
    text-decoration: underline;
  }
`
